<template>
  <div>
    <div>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-actions class="py-0">
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDelete">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-title
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="closeDelete">Cancel</v-btn>
            <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div>
      <v-dialog v-model="editDialog" max-width="500px">
        <v-card>
          <v-form @submit.prevent="putEditItem" ref="proxyPackageForm">
            <v-row no-gutters class="my-0 py-0">
              <v-spacer></v-spacer
              ><v-card-actions>
                <v-btn icon @click="clearEditDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-actions>
            </v-row>
            <v-card-title>
              <v-row class="mx-2">Edit {{ editedItem.name }}</v-row>
            </v-card-title>
            <v-card-text>
              <v-alert text color="red" type="error" v-if="errorMsg">
                {{ errorMsg }}
              </v-alert>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.name"
                      counter="30"
                      :rules="[inputRequired, minCharsRule, maxCharsRule]"
                      label="Package name"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.source"
                      :rules="[inputRequired, urlRule]"
                      label="Source"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.login"
                      :rules="[inputRequired]"
                      label="Login"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.password"
                      :rules="[inputRequired]"
                      label="Password"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" sm="4" md="4">
                    <v-text-field
                      v-model="editedItem.price"
                      :rules="[isNumericRule]"
                      label="Price"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-select
                      v-model="editedItem.currency"
                      :items="currencyItems"
                      label="Currency"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-select
                      v-model="editedItem.isActive"
                      item-value="attribute"
                      item-text="label"
                      :items="boolItems"
                      :rules="[inputRequired]"
                      label="Enabled"
                      required
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey darken-1" text @click="clearEditDialog">
                Cancel
              </v-btn>
              <v-btn color="primary" text @click="putEditItem"> Save </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </div>
    <h1 class="outline--text">
      System Proxies
      <proxy-package-add-dialog v-if="canManage"></proxy-package-add-dialog>
    </h1>
    <proxy-package-search></proxy-package-search>
    <v-row>
      <v-col class="outline--text mt-7" style="max-width: 700px">
        <div>
          <span v-if="!isLoading && this.totalCount > 0">
            <p v-if="this.totalCount > this.currentPage * this.itemsPerPage">
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.currentPage * this.itemsPerPage
              }}
              of {{ this.totalCount }} items
            </p>
            <p v-else>
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.totalCount
              }}
              of {{ this.totalCount }} items
            </p>
          </span>
        </div>
      </v-col>
      <v-col>
        <div class="d-flex justify-space-between">
          <v-spacer></v-spacer>
          <v-pagination
            v-if="showPages && pageCount > 1"
            class="my-3"
            v-model="pagination.page"
            :length="pageCount"
            :total-visible="7"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          ></v-pagination>
          <div class="d-flex mx-10">
            <v-select
              v-model="pagination.rowPerPage"
              item-text="label"
              item-value="attribute"
              :items="countPerPage"
              label="Show items"
              style="max-width: 80px"
            >
            </v-select>
          </div>
          <div class="d-flex">
            <v-select
              item-text="label"
              item-value="attribute"
              :items="sortAttributes"
              label="sortBy"
              v-model="sort.sortBy"
              style="max-width: 180px"
            ></v-select>
            <span v-if="sort.sortBy" class="mt-5 ml-2">
              <v-icon
                v-if="sort.desc"
                title="sortAsc"
                @click="sort.desc = false"
              >
                mdi-sort-descending
              </v-icon>
              <v-icon v-else title="sortDesc" @click="sort.desc = true">
                mdi-sort-ascending
              </v-icon>
            </span>
            <v-btn
              depressed
              rounded
              small
              outlined
              icon
              color="grey darken-1"
              style="float: right"
              class="my-5 mx-6"
              @click="printItems"
              ><v-icon> mdi-printer-outline </v-icon></v-btn
            >
            <v-btn
              depressed
              rounded
              small
              outlined
              icon
              color="grey darken-1"
              style="float: right"
              class="my-5"
              @click="exportItems"
              ><v-icon> mdi-file-export-outline </v-icon></v-btn
            >
          </div>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      id="itemsTable"
      :headers="headers"
      :loading="isLoading"
      :items="proxyPackages"
      :items-per-page="5"
      disable-pagination
      disable-filtering
      disable-sort
      hide-default-footer
      :item-class="itemClass"
      @click:row="
        (item) => {
          if (canSeeProxies) {
            $router.push({
              name: 'ProxyListPage',
              params: { id: item.id, name: item.name },
            });
          }
        }
      "
    >
      <template v-slot:item.isActive="{ item }">
        <v-switch
          class="pa-0 mx-0"
          dense
          color="#67bb6a"
          v-model="item.isActive"
          @click.stop="editStatus(item)"
          :disabled="!canManage"
        >
          <template v-slot:label>
            <span class="black--text">{{ item.id.toString() }}</span>
          </template>
        </v-switch>
      </template>
      <template v-slot:item.name="{ item }">
        <v-icon small class="mr-2">mdi-archive-outline</v-icon>
        {{ item.name }}
      </template>
      <template v-slot:item.source="{ item }">
        <a target="_blank" @click.stop :href="item.source" class="blue--text">
          {{ item.source }}
        </a>
      </template>
      <template v-slot:item.status="{ item }">
        <v-row>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <img
                  v-if="item.status === 'error'"
                  class="mx-2"
                  :src="require('@/assets/alert-box-outline.png')"
                  alt=""
                />
                <img
                  v-else-if="item.status === 'warning'"
                  class="mx-2"
                  :src="require('@/assets/alert-outline.png')"
                  alt=""
                />
                <img
                  v-else-if="item.status === 'no data'"
                  class="mx-2"
                  :src="require('@/assets/broadcast-off.png')"
                  alt=""
                />
                <img
                  v-else
                  class="mx-2"
                  :src="require('@/assets/check-circle-outline.png')"
                  alt=""
                />
              </span>
            </template>
            <p v-if="item.status === 'no data'" class="my-2">No data</p>
            <p v-else-if="item.status === 'error'" class="my-2">Error</p>
            <p v-else-if="item.status === 'warning'" class="my-2">Warning</p>
            <p v-else class="my-2">Success</p>
          </v-tooltip>
          <span v-if="item.status === 'no data'" class="grey--text mt-1"
            >NA</span
          >
          <span v-else-if="item.status === 'warning'" class="orange--text mt-1">
            Warning
          </span>
          <span v-else-if="item.status === 'error'" class="red--text mt-1">
            Error
          </span>
          <span v-else class="green--text mt-1"> Success </span>
        </v-row>
      </template>
      <template v-slot:item.Actions="{ item }">
        <v-icon v-if="canManage" class="mr-2" @click.stop="editItem(item)">
          mdi-pencil-outline
        </v-icon>
        <v-icon v-if="canManage" color="red" @click.stop="deleteItem(item)">
          mdi-delete-forever-outline
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import _ from "lodash";
import { NEW_CREATED_PROXY_PACKAGE } from "@/utils/events";
import { EventBus } from "@/utils";
import { mapGetters, mapState } from "vuex";
import BaseListPage from "./base/BaseListPage";
import {
  defaultFilters,
  emptyFilters,
  multipleFilters,
} from "@/store/modules/proxyPackage/state";
import {
  DELETE_PROXY_PACKAGE,
  FETCH_PROXY_PACKAGE,
  FETCH_PROXY_PACKAGES_FILE,
  SET_FILTERS,
  UPDATE_PROXY_PACKAGE,
} from "@/store/modules/proxyPackage/actions";
import ProxyPackageSearch from "@/views/proxyPackage/list/ProxyPackageSearch";
import ProxyPackageAddDialog from "@/views/proxyPackage/item/ProxyPackageAddDialog";
import { FETCH_PROXY_PACKAGES } from "@/store/modules/proxyPackage/actions";
import { SET_EMPTY_FILTERS } from "@/store/modules/tag/actions";

import acl from "@/mixin/acl";
import {
  RESOURCE_PROXIES,
  RESOURCE_PROXY_PACKAGES_MANAGEMENT,
} from "@/store/modules/admin/getters";

export default {
  name: "ProxyPackagesPage",
  mixins: [acl],
  extends: BaseListPage,
  components: { ProxyPackageSearch, ProxyPackageAddDialog },
  props: {
    limit: {
      required: false,
    },
  },
  data() {
    return {
      defaultFilters,
      emptyFilters,
      multipleFilters,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      dialogDelete: false,
      showPages: false,
      boolItems: [
        {
          attribute: 1,
          label: "Yes",
        },
        {
          attribute: 0,
          label: "No",
        },
      ],
      currencyItems: ["UAH", "USD", "EUR", "RUB"],
      errorMsg: "",
      inputRequired: (v) => !!v || v === 0 || "Required field",
      requiredRule: (v) => !!v || "Not enough characters to save proxy package",
      isNumericRule: (v) => !v || !isNaN(v) || "Not a number",
      minCharsRule: (v) => {
        if (!(v instanceof Array)) {
          return (v && v.length >= 2) || "Input too short!";
        } else {
          const tooShortItems = v.filter(function (item) {
            return item.length < 2;
          });
          return tooShortItems.length === 0 || "Input too short!";
        }
      },
      maxCharsRule: (v) => {
        if (!(v instanceof Array)) {
          return (v && v.length <= 30) || "Input too long!";
        } else {
          const tooLongItems = v.filter(function (item) {
            return item.length > 30;
          });
          return tooLongItems.length === 0 || "Input too long!";
        }
      },
      urlRule: (v) =>
        new RegExp(
          "^(https?:\\/\\/)?" +
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
            "((\\d{1,3}\\.){3}\\d{1,3}))" +
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*",
          "i"
        ).test(v) || "Incorrect URL",
      countPerPage: [
        {
          attribute: 20,
          label: "20",
        },
        {
          attribute: 50,
          label: "50",
        },
        {
          attribute: 100,
          label: "100",
        },
        {
          attribute: 5000,
          label: "All",
        },
      ],
      headers: [
        {
          text: "",
          value: "isActive",
          width: "50",
        },
        {
          text: "Package Name",
          value: "name",
          width: "200",
        },
        {
          text: "Source",
          value: "source",
          width: "250",
        },
        {
          text: "Price",
          value: "formatted_price",
          width: "150",
          align: "center",
        },
        {
          text: "Proxies Number",
          value: "proxiesNumber",
          width: "175",
          align: "center",
        },
        {
          text: "Status",
          value: "status",
          width: "150",
        },
        {
          text: "Created",
          value: "createdAt",
          width: "200",
        },
        {
          width: "125",
          text: "",
          value: "Actions",
          align: "center",
        },
      ],
      sortAttributes: [
        {
          attribute: "id",
          label: "ID",
        },
        {
          attribute: "proxy_number",
          label: "Proxies Number",
        },
        {
          attribute: "proxiesStatus",
          label: "Status",
        },
        {
          attribute: "isActive",
          label: "Enabled",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("proxyPackage", [
      "proxyPackageList",
      "totalCount",
      "currentPage",
      "pageCount",
      "offset",
      "formattedFilters",
    ]),
    ...mapState("proxyPackage", ["sort", "pagination", "filters"]),
    canManage: function () {
      return this.hasAccess([RESOURCE_PROXY_PACKAGES_MANAGEMENT]);
    },
    canSeeProxies: function () {
      return this.hasAccess([RESOURCE_PROXIES]);
    },
    itemsPerPage: function () {
      return this.pagination.rowPerPage;
    },
    proxyPackages: function () {
      if (this.isLoading) {
        return [];
      }
      return this.proxyPackageList;
    },
  },
  watch: {
    "pagination.rowPerPage": function () {
      this.pagination.page = 1;
    },
  },
  methods: {
    clearEditDialog() {
      this.errorMsg = "";
      this.closeEditDialog();
    },
    applyFilters: function (payload) {
      return this.$store.dispatch(`proxyPackage/${SET_FILTERS}`, payload.data);
    },
    fetchItems: function () {
      const vm = this;
      vm.isLoading = true;
      vm.$store.dispatch(`proxyPackage/${FETCH_PROXY_PACKAGES}`).then(
        () => {
          vm.isLoading = false;
          vm.showPages = true;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    fetchItem: function (itemId, how) {
      const vm = this;
      vm.$store
        .dispatch(`proxyPackage/${FETCH_PROXY_PACKAGE}`, { itemId, how })
        .then(
          () => {},
          (error) => {
            console.log(error);
          }
        );
    },
    editItem: function (item) {
      this.editedItem = _.merge({}, item);
      this.editDialog = true;
    },
    editStatus(item) {
      this.editedItem = _.merge({}, item);
      this.putEditItem();
    },
    putEditItem() {
      if (this.editDialog) {
        if (!this.$refs.proxyPackageForm.validate()) {
          this.errorMsg = "Please check all fields!";
          return;
        }
      }
      const vm = this;
      let { editedItem } = this;
      let { id } = editedItem;
      vm.$store
        .dispatch(`proxyPackage/${UPDATE_PROXY_PACKAGE}`, {
          id,
          editedItem,
        })
        .then(
          () => {
            vm.fetchItem(this.editedItem.id);
            if (this.editDialog) {
              this.clearEditDialog();
            }
          },
          (error) => {
            this.errorMsg = error;
          }
        );
    },
    closeDelete() {
      this.dialogDelete = false;
      this.selectedItem = null;
    },
    deleteItem(item) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },
    deleteItemConfirm: function () {
      let vm = this;
      vm.$store
        .dispatch(`proxyPackage/${DELETE_PROXY_PACKAGE}`, vm.selectedItem)
        .then(
          function () {
            if (vm.proxyPackages.length === 0) {
              vm.$store.dispatch(`proxyPackage/${SET_EMPTY_FILTERS}`);
              vm.fetchItems();
            }
          },
          function (error) {
            vm.errorMsg = error;
          }
        )
        .catch(() => {
          vm.errorMsg = "Something went wrong";
        });
      this.dialogDelete = false;
    },
    exportItems: function () {
      const vm = this;
      vm.$store.dispatch(`proxyPackage/${FETCH_PROXY_PACKAGES_FILE}`).then(
        () => {},
        (error) => {
          console.log(error);
        }
      );
    },
    printItems: function () {
      this.$htmlToPaper("itemsTable", null, () => {});
    },
  },
  mounted() {
    const vm = this;
    vm.applyRouteParams();
    vm.fetchItems();
  },
  created() {
    EventBus.$on(NEW_CREATED_PROXY_PACKAGE, (id) => {
      this.fetchItem(id, "push");
    });
  },
};
</script>
