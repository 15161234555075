<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        depressed
        rounded
        color="primary"
        style="float: right"
        v-bind="attrs"
        v-on="on"
        >Add proxy package</v-btn
      >
    </template>
    <v-card>
      <v-form @submit.prevent="saveProxyPackage" ref="proxyPackageForm">
        <v-row no-gutters class="my-0 py-0">
          <v-spacer></v-spacer
          ><v-card-actions>
            <v-btn icon @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-row>
        <v-card-title>
          <v-row class="mx-2">Add proxy package</v-row>
        </v-card-title>
        <v-card-text>
          <v-alert text color="red" type="error" v-if="errorMsg">
            {{ errorMsg }}
          </v-alert>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="newItem.name"
                  counter="30"
                  :rules="[inputRequired, minCharsRule, maxCharsRule]"
                  label="Package name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="newItem.source"
                  :rules="[inputRequired, urlRule]"
                  label="Source"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  v-model="newItem.login"
                  :rules="[inputRequired]"
                  label="Login"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  v-model="newItem.password"
                  :rules="[inputRequired]"
                  label="Password"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" sm="4" md="4">
                <v-text-field
                  v-model="newItem.price"
                  :rules="[isNumericRule]"
                  label="Price"
                >
                </v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-select
                  v-model="newItem.currency"
                  :items="currencyItems"
                  label="Currency"
                  required
                ></v-select>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-select
                  v-model="newItem.isActive"
                  item-value="attribute"
                  item-text="label"
                  :items="boolItems"
                  :rules="[inputRequired]"
                  label="Enabled"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="close"> Cancel </v-btn>
          <v-btn color="primary" text @click="saveProxyPackage"> Save </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from "@/utils";
import { NEW_CREATED_PROXY_PACKAGE } from "@/utils/events";
import { CREATE_PROXY_PACKAGE } from "@/store/modules/proxyPackage/actions";

export default {
  name: "ProxyPackageAddDialog",
  data() {
    return {
      dialog: false,
      itemsLength: [],
      items: [],
      newItem: {
        name: null,
        source: null,
        login: null,
        password: null,
        price: "0",
        currency: "UAH",
        isActive: 1,
      },
      defaultItem: {
        name: null,
        source: null,
        login: null,
        password: null,
        price: "0",
        currency: "UAH",
        isActive: 1,
      },
      boolItems: [
        {
          attribute: 1,
          label: "Yes",
        },
        {
          attribute: 0,
          label: "No",
        },
      ],
      currencyItems: ["UAH", "USD", "EUR", "RUB"],
      errorMsg: "",
      inputRequired: (v) => !!v || v === 0 || "Required field",
      requiredRule: (v) => !!v || "Not enough characters to save proxy package",
      isNumericRule: (v) => !v || !isNaN(v) || "Not a number",
      minCharsRule: (v) => {
        if (!(v instanceof Array)) {
          return (v && v.length >= 2) || "Input too short!";
        } else {
          const tooShortItems = v.filter(function (item) {
            return item.length < 2;
          });
          return tooShortItems.length === 0 || "Input too short!";
        }
      },
      maxCharsRule: (v) => {
        if (!(v instanceof Array)) {
          return (v && v.length <= 30) || "Input too long!";
        } else {
          const tooLongItems = v.filter(function (item) {
            return item.length > 30;
          });
          return tooLongItems.length === 0 || "Input too long!";
        }
      },
      urlRule: (v) =>
        new RegExp(
          "^(https?:\\/\\/)?" +
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
            "((\\d{1,3}\\.){3}\\d{1,3}))" +
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*",
          "i"
        ).test(v) || "Incorrect URL",
    };
  },
  watch: {
    dialog(val) {
      this.errorMsg = null;
      val || this.close();
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.errorMsg = null;
      this.$refs.proxyPackageForm.resetValidation();
      this.newItem = Object.assign({}, this.defaultItem);
    },
    saveProxyPackage() {
      let vm = this;
      return this.createProxyPackage(function (data) {
        vm.close();
        EventBus.$emit(NEW_CREATED_PROXY_PACKAGE, data.id);
      });
    },
    createProxyPackage(resolve) {
      let vm = this;
      if (!vm.$refs.proxyPackageForm.validate()) {
        this.errorMsg = "Please check all fields!";
      } else {
        this.$store
          .dispatch(`proxyPackage/${CREATE_PROXY_PACKAGE}`, this.newItem)
          .then(resolve, function (error) {
            vm.errorMsg = error;
          })
          .catch(() => {
            this.errorMsg = "Something went wrong";
          });
      }
    },
  },
};
</script>
